<template>
  <div class="mod-home" v-html="html">
  </div>
</template>

<script>
  export default {
    data () {
      return {
        html: ''
      }
    },
    activated() {
      // this.html = this.searchMenu(this.$store.state.common.menuList, this.$route.params.id)
    },
    created() {
      this.$router.afterEach(to => {
        const path = to.fullPath.substr(0, to.fullPath.lastIndexOf('/'))
        if (path === '/custom') {
          this.html = this.searchMenu(this.$store.state.common.menuList, to.params.id)
        }
      })
      this.html = this.searchMenu(this.$store.state.common.menuList, this.$route.params.id)
    },
    methods: {
      searchMenu(list, id) {
        if (list) {
          for (var m of list) {
            if (Number(m.menuId) === Number(id)) {
              return m.url
            } else {
              if (m.list) {
                var r = this.searchMenu(m.list, id)
                if (r !== '') {
                  return r
                }
              }
            }
          }
        }
        return ''
      }
    }
  }
</script>

<style>
  .mod-home {
    line-height: 1.5;
  }
</style>

